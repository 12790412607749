
import { Options, Vue } from "vue-class-component";
import convertDecimalWithComma from "@/app/infrastructures/misc/common-library/ConvertDecimalWithComma";
import { CargoDetailStt, CargoInputData } from "@/domain/entities/Cargo";
import { storeDatatoLocalStorage } from "@/app/infrastructures/misc/LocalStorage";
import { CargoController } from "@/app/ui/controllers/CargoController";
import { gtmCargoData } from "@/app/ui/views/cargo/modules/gtm";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import { AccountController } from "@/app/ui/controllers/AccountController";

@Options({})
export default class CargoTableSttSection extends Vue {
  mounted() {
    this.updateEstimationVolumeAndWeight();
  }
  convertDecimal(value: any) {
    return convertDecimalWithComma(value, 2);
  }

  get formBooking() {
    return CargoController.form;
  }

  get isCargoPlane() {
    return this.formBooking.cargoType === "plane";
  }

  get isCargoPlaneAuto() {
    return (
      this.formBooking.cargoType === "plane" &&
      this.formBooking.cargoTypeStatus === "auto"
    );
  }

  get sttResult(): CargoDetailStt[] {
    return CargoController.sttResultFinal;
  }

  get bagResult(): {
    baggingNumber: string;
    bagGrossWeight: number;
    stt: CargoDetailStt[];
  }[] {
    return CargoController.bagResult;
  }

  get totalStt() {
    return this.sttResult.length;
  }

  get totalSttPieces() {
    return CargoController.totalSttPieces;
  }

  get form() {
    return CargoController.form;
  }

  // airport
  get isPlaneAuto() {
    return (
      this.form.cargoType === "plane" && this.form.cargoTypeStatus === "auto"
    );
  }

  get sourceSttFromLocalStorage() {
    return CargoController.sourceSttFromLocalStorage;
  }

  get totalGrossWeights() {
    return CargoController.totalGrossWeights || "";
  }

  get totalVolumeWeights() {
    return CargoController.totalVolumeWeights;
  }

  pagination = {
    page: 1,
    limit: 50
  };

  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-10 text-center whitespace-no-wrap",
        styleCustom: "align-center",
        render: (_: any, index: number) => {
          return `<div class="text-black-lp-300">${index +
            1 +
            this.pagination.limit * (this.pagination.page - 1)}</div>`;
        }
      },
      {
        name: "No. bag",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: CargoDetailStt) => {
          return `<div class="text-black-lp-300 whitespace-no-wrap">
                  ${item.baggingVendorNo || item.baggingNumber || "-"}
                </div>`;
        }
      },
      {
        name: "No. STT",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: any) => {
          let html = `<div class="space-y-3">`;
          if (item.sttDetails) {
            for (const x in item.sttDetails) {
              html += `<div class="text-black-lp-300">${item.sttDetails[x].sttNumber}</div>`;
            }
            html += `</div>`;
            return html;
          }
          return `<div class="text-black-lp-300 whitespace-no-wrap">
                  ${item.sttNumber}
                </div>`;
        }
      },
      {
        name: this.$t("No. referensi"),
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: any) => {
          let html = `<div class="space-y-3">`;
          if (item.sttDetails) {
            for (const x in item.sttDetails) {
              html += `<div class="text-black-lp-300">${item.sttDetails[x].sttNoRefExternal || "-"}</div>`;
            }
            html += `</div>`;
            return html;
          }
          return `<div class="text-black-lp-300 whitespace-no-wrap">
                  ${item.sttNoRefExternal || "-"}
                </div>`;
        }
      },
      {
        name: this.$t("Total koli"),
        styleHead: "w-24 text-left whitespace-no-wrap",
        render: (item: any) => {
          let html = `<div class="space-y-3">`;
          if (item.sttDetails) {
            for (const x in item.sttDetails) {
              html += `<div class="text-black-lp-300">${item.sttDetails[x].totalPieces}</div>`;
            }
            html += `</div>`;
            return html;
          }
          return `<div class="text-black-lp-300 whitespace-no-wrap">
                  ${item.totalPieces}
                </div>`;
        }
      },
      {
        name: this.$t("Berat kotor"),
        styleHead: "w-32 text-left whitespace-no-wrap",
        render: (item: any) => {
          let html = `<div class="space-y-3">`;
          if (item.sttDetails) {
            for (const x in item.sttDetails) {
              html += `<div class="text-black-lp-300">${convertDecimalWithComma(
                item.sttDetails[x].grossWeigth,
                2
              )} Kg</div>`;
            }
            html += `</div>`;
            return html;
          }
          return `<div class="text-black-lp-300 whitespace-no-wrap">
                  ${convertDecimalWithComma(item.grossWeigth, 2)} Kg
                </div>`;
        }
      },
      {
        name: this.$t("Berat dimensi"),
        styleHead: "w-32 text-left whitespace-no-wrap",
        render: (item: any) => {
          let html = `<div class="space-y-3">`;
          if (item.sttDetails) {
            for (const x in item.sttDetails) {
              html += `<div class="text-black-lp-300">${convertDecimalWithComma(
                item.sttDetails[x].volumeWeight,
                2
              )} Kg</div>`;
            }
            html += `</div>`;
            return html;
          }
          return `<div class="text-black-lp-300 whitespace-no-wrap">
                  ${convertDecimalWithComma(item.volumeWeight, 2)} Kg
                </div>`;
        }
      },
      {
        name: this.$t("Produk"),
        styleHead: "w-32 text-left whitespace-no-wrap",
        render: (item: any) => {
          let html = `<div class="space-y-3">`;
          if (item.sttDetails) {
            for (const x in item.sttDetails) {
              html += `<div class="text-black-lp-300">${item.sttDetails[x].productType}</div>`;
            }
            html += `</div>`;
            return html;
          }
          return `<div class="text-black-lp-300 whitespace-no-wrap">
                  ${item.productType}
                </div>`;
        }
      },
      {
        name: this.$t("Komoditas"),
        styleHead: "w-32 text-left whitespace-no-wrap",
        render: (item: any) => {
          let html = `<div class="space-y-3">`;
          if (item.sttDetails) {
            for (const x in item.sttDetails) {
              html += `<div class="text-black-lp-300">${item.sttDetails[x].commodity}</div>`;
            }
            html += `</div>`;
            return html;
          }
          return `<div class="text-black-lp-300 whitespace-no-wrap">
                  ${item.commodity}
                </div>`;
        }
      },
      {
        name: "Atur",
        key: "actionable_column",
        styleHead: "w-36 text-left whitespace-no-wrap",
        showButton: () => {
          return true;
        }
      }
    ];
  }

  get isAccountForeign() {
    return AccountController.accountData.accountIsForeign;
  }

  customButton = {
    outline: true,
    iconLeft: "trash-red",
    title: this.isAccountForeign ? "Delete" : "Hapus",
    textColor: "red-lp-100",
    color: "red-lp-100",
    small: false,
    customClass: "whitespace-no-wrap",
    disabled: false,
    clickFunction: (item: any) => {
      if (!item.baggingNumber) {
        this.wordingDelete =
          "STT yang Anda hapus akan hilang dari daftar pengiriman";
      } else {
        this.wordingDelete =
          "Semua STT dari No. Bag yang sama akan terhapus dari daftar pengiriman";
      }
      this.itemToDelete = item;
      this.actionModalDelete(true);
      CargoController.setCacheCommodityGroup(true);
    }
  };

  // popup delete
  get isShowPopup(): boolean {
    return this.successModalDelete || this.isShowModalDeleteConfirmation;
  }

  wordingDelete = "";
  itemToDelete: any = "";
  isShowModalDeleteConfirmation = false;
  isUnpaid = false;
  successModalDelete = false;

  setSuccessModalDelete(bool: boolean) {
    this.successModalDelete = bool;
  }

  actionModalDelete(bool: boolean) {
    this.isShowModalDeleteConfirmation = bool;
    this.isUnpaid = false;
  }

  isDeleteCargoItem() {
    if (this.itemToDelete.sttDetails) {
      this.deleteSttResultByBagNumber(
        this.itemToDelete.baggingNumber || this.itemToDelete.baggingVendorNo
      );
    } else {
      this.deleteSttResultBySttNumber(this.itemToDelete.sttNumber);
    }
    this.actionModalDelete(false);
    this.setSuccessModalDelete(true);
    CargoController.setFillForm(true);
    CargoController.setIsDeleteStt(true);
    this.isUnpaid = false;
    const res = isNaN(+this.totalGrossWeights)
      ? +this.totalGrossWeights.replace(",", ".") < 10
      : +this.totalGrossWeights < 10;
    CargoController.setNotificationWeight(this.totalGrossWeights && res);
    CargoController.mappingCommodityOptions();
  }

  deleteSttResultByBagNumber(bagNumber: string) {
    const deletedBagging = this.bagResult.filter(
      (item: { baggingNumber: string; stt: CargoDetailStt[] }) => {
        return item.baggingNumber === bagNumber;
      }
    )[0];
    this.$emit("dataChange");
    CargoController.setSTTResultFinal(
      this.sttResult.filter((item: CargoDetailStt) => {
        return item.baggingNumber !== bagNumber;
      })
    );
    storeDatatoLocalStorage("cargo", this.sttResult);
    CargoController.setSourceFromLocalStorage(this.sttResult);
    CargoController.setBagResult(
      this.bagResult.filter(
        (item: { baggingNumber: string; stt: CargoDetailStt[] }) => {
          return item.baggingNumber !== bagNumber;
        }
      )
    );
    const totalActualGrossWeight =
      this.cargoInputForm.calcuateActualCargoGrossWeight +
      -(deletedBagging?.bagGrossWeight ?? this.itemToDelete.grossWeigth); // explanation https://github.com/Lionparcel/medusa/pull/5753/files#r1285584448
    CargoController.setTotalActualGrossWeight(totalActualGrossWeight);
    CargoController.resetTotalColiOnLocalStorage();
    this.onChangeTotalColi(CargoController.totalSttPieces);

    this.updateEstimationVolumeAndWeight();
    this.calculationVolumeWeight();
    storeDatatoLocalStorage("cargo-bag", this.bagResult);
    if (!this.sttResult.length) {
      CargoController.setResetDestinationCity();
      CargoController.resetCargoInputForm(new CargoInputData());
      CargoController.setFormDetailPieces({
        totalPieces: 0,
        dimensionPieces: [
          {
            pieces: 0,
            length: 30,
            width: 20,
            height: 10,
            grossWeight: 1,
          },
        ],
      });
      CargoController.setHaveBeenSubmit(false);
    }
  }

  deleteSttResultBySttNumber(sttNumber: string) {
    this.$emit("dataChange");
    CargoController.setSTTResultFinal(
      this.sttResult.filter((item: CargoDetailStt) => {
        return item.sttNumber !== sttNumber;
      })
    );
    const newSttResult = this.sourceSttFromLocalStorage.filter((item: any) => {
      return item.sttNumber !== sttNumber;
    });
    storeDatatoLocalStorage("cargo", newSttResult);
    CargoController.setSourceFromLocalStorage(newSttResult);
    const totalActualGrossWeight =
      this.cargoInputForm.calcuateActualCargoGrossWeight +
      -this.itemToDelete.grossWeigth; // explanation https://github.com/Lionparcel/medusa/pull/5753/files#r1285584448
    CargoController.setTotalActualGrossWeight(totalActualGrossWeight);
    CargoController.resetTotalColiOnLocalStorage();
    this.onChangeTotalColi(CargoController.totalSttPieces);
    CargoController.setTotalPcs(CargoController.totalSttPieces);
    CargoController.mappingCommodityOptions();
    this.updateEstimationVolumeAndWeight();
    this.calculationVolumeWeight();
    if (!this.sttResult.length) {
      CargoController.setResetDestinationCity();
      CargoController.resetCargoInputForm(new CargoInputData());
      CargoController.setFormDetailPieces({
        totalPieces: 0,
        dimensionPieces: [
          {
            pieces: 0,
            length: 30,
            width: 20,
            height: 10,
            grossWeight: 1,
          },
        ],
      });
      CargoController.setHaveBeenSubmit(false);
    }
  }

  onChangeTotalColi(value: number) {
    CargoController.setOnChangeTotalColi(value);
  }

  calculationVolumeWeight() {
    CargoController.calculationVolumeWeight(CargoController.totalSttPieces);
  }

  set setEstimationTotalVolumeWeight(value: any) {
    CargoController.setEstimationTotalVolumeWeight(value);
  }

  set setEstimationTotalGrossWeight(value: any) {
    CargoController.setEstimationTotalGrossWeight(value);
  }

  updateEstimationVolumeAndWeight() {
    this.setEstimationTotalVolumeWeight = this.totalVolumeWeights;
    this.setEstimationTotalGrossWeight = this.totalGrossWeights;
  }

  get cargoInputForm() {
    return CargoController.cargoInputForm;
  }

  get popupModalData() {
    // if success delete
    if (this.successModalDelete) {
      // GTM
      if (this.formBooking.cargoType && this.formBooking.cargoType !== "ship") {
        const gtmDeleteSttSuccess = gtmCargoData({
          type: this.formBooking.cargoType,
          isAuto: this.isCargoPlaneAuto
        }).deleteSttSuccess;

        dataLayer(
          gtmDeleteSttSuccess.eventName,
          gtmDeleteSttSuccess.property,
          []
        );
      }
      return {
        success: {
          action: () => {
            this.setSuccessModalDelete(false);
          },
          text: "OK"
        },
        title: this.$t("STT Berhasil Dihapus"),
        message: this.$t("Anda dapat menambahkan STT baru ke daftar pengiriman"),
        image: "image-modal-success"
      };
    }
    // show popup delete
    return {
      success: {
        action: () => this.isDeleteCargoItem(),
        text: this.$t("Hapus")
      },
      cancel: {
        action: () => this.actionModalDelete(false),
        text: this.$t("Batal")
      },
      title: this.$t("Hapus STT"),
      message: `${this.$t(this.wordingDelete)}`,
      image: "badge-confirmation-general"
    };
  }
}
